import { configPrepare } from "@/helpers/vue-good-table"
import { translateOtaName } from "@/helpers/common"

const defaultTableClasses = {
  tdClass: "reservations-table-td",
  thClass: "reservations-table-th"
}

export const i18nPrefix = "reservations.columns"

const fields = [
  "status",
  "payment_status",
  {
    field: "ota_name",
    formatFn: translateOtaName
  },
  "reserved_at",
  "cancelled_at",
  "car_class",
  "plan_code",
  "plan_name",
  "driver_name",
  "pick_up_shop",
  "pick_up_at",
  "drop_off_shop",
  "drop_off_at",
  "reservation_code",
  "client_control_number",
  "driver_phone",
  "reservation_person_name",
  "reservation_person_address",
  "reservation_person_email",
  "number_of_passengers",
  "transmission_type",
  "smoking",
  {
    field: "options",
    sortable: false
  },
  {
    field: "insurance_policy",
    sortable: false
  },
  "arrival_flight_code",
  "departure_flight_code",
  "total_basic_fee",
  "used_points",
  "cancel_fee",
  "promotion_program",
  "coupon_amount",
  "total_fee",
  "memo",
  {
    field: "actions",
    sortable: false
  }
]

export const columns = configPrepare(fields, { i18nPrefix, defaults: defaultTableClasses, backendSort: true })
