export const fields = [
  "status",
  "payment_status",
  "ota_name",
  "reserved_at",
  "cancelled_at",
  "car_class",
  "plan_code",
  "plan_name",
  "driver_name",
  "driver_name_kana",
  "pick_up_shop",
  "pick_up_at",
  "drop_off_shop",
  "drop_off_at",
  "reservation_code",
  "client_control_number",
  "driver_phone",
  "reservation_person_name",
  "reservation_person_name_kana",
  "reservation_person_address",
  "reservation_person_email",
  "representative_name",
  "representative_name_kana",
  "representative_phone",
  "number_of_passengers",
  "transmission_type",
  "smoking",
  "options",
  "insurance_policy",
  "arrival_flight_code",
  "departure_flight_code",
  "total_basic_fee",
  "used_points",
  "cancel_fee",
  "promotion_program",
  "coupon_amount",
  "total_fee",
  "memo"
]

export const dataFields = [
  "ota_reservation_id",
  "ota_pick_up_shop_id",
  "ota_drop_off_shop_id",
  "ota_pick_up_shop_name",
  "ota_drop_off_shop_name"
]

export const originalDataFields = {
  jalan: [
    "granted_points",
    "billing_information",
    "change_log",
    "fee",
    "insurance_fee",
    "system_fee",
    "point_fee",
    "additional_point_fee",
    "drop_off_fee",
    "options_fee",
    "midnight_fee"
  ],
  rakuten: [
    "emergency_phone",
    "career",
    "model",
    "car_attributes",
    "enterprise_car_class_id",
    "rakuten_car_class",
    "accommodation",
    "accommodation_number",
    "payment_method",
    "payment_id",
    "return_policy",
    "fee_oneway",
    "fee_options",
    "fee_extra_time_pickup",
    "fee_extra_time_return",
    "fee_actual",
    "fee_online",
    "reserved_confirmed_at",
    "cancelled_confirmed_at"
  ],
  tabirai: [
    "pick_up_hotel",
    "drop_off_hotel",
    "reservation_site",
    "reply",
    "client_name_kanji",
    "client_notes",
    "person_in_charge_memo",
    "dont_check_car_inventory",
    "dont_check_option_inventory",
    "check_error",
    "fee",
    "fee_type",
    "fee_per_car",
    "fee_shop_per_car",
    "fee_options",
    "fee_b2b_cancellation"
  ]
}
