<template lang="pug">
  .reservation-item
    .row
      .col-xs-12.col-md-6.item-field-wrapper(v-for="({ label, content }, field) in preparedFields")
        .item-field
          .field.w-50 {{ label }}
          AppTextExpandable.value.w-50(:text="content")
</template>

<script>
  // misc
  import { each, has } from "lodash-es"
  import { translateOtaName } from "@/helpers/common"
  import { i18nPrefix as i18nTablePrefix } from "./ReservationsTableConfig"
  import { fields, dataFields, originalDataFields } from "./ReservationItemConfig"

  export default {
    props: {
      item: Object
    },

    components: {
      AppTextExpandable: () => import("@/components/elements/AppTextExpandable")
    },

    computed: {
      preparedFields() {
        const { item } = this
        const { data: itemData = {} } = item
        const data = {
          ...this.pickDataFields(item, fields, true),
          ...this.pickDataFields(itemData, dataFields),
          ...this.pickDataFields(itemData, originalDataFields[this.ota])
        }
        return data
      },

      ota() {
        return this.item.ota_name
      }
    },

    methods: {
      pickDataFields(data, fields, fromTable) {
        const picked = {}
        each(fields, field => {
          if (has(data, field)) {
            let content
            if (field == "ota_name") {
              content = translateOtaName(data[field])
            } else {
              content = data[field] || "—"
            }
            picked[field] = {
              content: content,
              label: this.translatedFieldName(field, fromTable)
            }
          }
        })
        return picked
      },

      originalDataFieldsForOta(ota_name) {
        return originalDataFields[ota_name]
      },

      translatedFieldName(field, fromTable) {
        if (fromTable) {
          return this.$t(`${i18nTablePrefix}.${field}`)
        }
        return this.$t(`reservations.data.${this.ota}.${field}`)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .reservation-item
    overflow: hidden
    heigth: 100%

    .item-field
      display: flex
      border-bottom: 1px solid $default-purple-light-line

      .field
        opacity: 0.7
</style>
