<template lang="pug">
  .reservations-table
    VueGoodTable(
      :rows="items"
      :columns="columns"
      :sort-options="{ enabled: true, initialSortBy: order }"
      @on-sort-change="$emit('change-order', $event)"
    )
      template(v-slot:table-column="props")
        span(:title="props.column.label") {{ $t(props.column.label) }}
      template(v-slot:table-row="{ column, row, formattedRow }")
        .actions(v-if="column.field === 'actions'")
          AppButton.btn-primary(
            title="reservations.buttons.more"
            @click="openReservationModal(row)"
          )
        AppTextExpandable(
          v-else
          :key="row.id"
          :text="formatRow(formattedRow[column.field])"
        )
</template>

<script>
  // mixins
  import withModal from "@/mixins/withModal"

  // components
  import { VueGoodTable } from "vue-good-table"
  import ReservationItem from "./ReservationItem"

  // misc
  import { i18n } from "@/config"
  import "vue-good-table/dist/vue-good-table.css"
  import { columns } from "./ReservationsTableConfig"

  export default {
    props: {
      loading: {
        type: Boolean,
        default: true
      },
      items: {
        type: Array,
        default: () => new Array()
      },
      order: {
        type: Object,
        default: () => new Object()
      }
    },

    components: {
      VueGoodTable,
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppButton: () => import("@/components/elements/AppButton"),
      AppTextExpandable: () => import("@/components/elements/AppTextExpandable")
    },

    mixins: [withModal],

    data() {
      return {
        columns
      }
    },

    methods: {
      openReservationModal(item) {
        this.$openModal({
          component: ReservationItem,
          title: i18n.t("reservations.titles.details_modal"),
          closeOnClick: true,
          size: "large",
          props: {
            item
          }
        })
      },

      formatRow(data) {
        return data || "—"
      }
    }
  }
</script>

<style lang="sass">
  .reservations-table
    .actions
      min-width: 60px
    &-td, &-th
      max-width: 300px
    &-th
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis

    &-options
      display: flex
      width: 40%
      float: right
      justify-content: flex-end
      margin-top: 20px

      .multiselect
        width: 70px
        margin-left: 10px

        &__tags
          min-height: 38px
</style>
